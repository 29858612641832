import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import "/public/css/free.css";

import '/public/css/font-awesome.css';

import router from './router/router.js'

import store from './store'
// import store from './store/demo'
// this.$store.dispatch('saveUserName','cendxiaNet') // 赋值
// this.$store.state.username // 读取值

import common from './comon/common.js'
Vue.prototype.$common = common


import storage from './storage'
Vue.prototype.$storage = storage


import api from './api/api'
Vue.prototype.$api = api

import computed from '../public/js/computed.js'
Vue.prototype.$computed = computed

// 修改页面标题
router.beforeEach((to, from, next) => {
    if(to.meta.title){
        document.title = to.meta.title
    }
    next()
})

import divider10 from './components/common/divider-10.vue'
Vue.component('divider-10',divider10)

import h40 from './components/common/h-40.vue'
Vue.component('h-40',h40)

// footer
import footer from './components/common/footer.vue';
Vue.component('commonFooter',footer)

// 分割线
import divider from './components/common/divider.vue';
Vue.component('divider',divider)

import nothing from './components/common/nothing.vue'
Vue.component('nothing',nothing)

import waterfall from 'vue-waterfall2'
Vue.use(waterfall)


import {Swipe,SwipeItem,Divider,Grid,GridItem,Form,Field,Popup,Cascader,Button,NavBar,Radio,RadioGroup,List,Cell,Picker,CellGroup,Toast,AddressEdit,Tabs,Tab,PullRefresh,Area,Icon,Dialog,NoticeBar,Step,Steps,Empty,Tag,Pagination,Search} from 'vant';
import 'vant/lib/index.css';

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

Vue.use(Swipe).use(SwipeItem).use(Divider).use(Grid).use(GridItem).use(Form).use(Field).use(Popup).use(Cascader).use(Button).use(NavBar).use(Radio).use(RadioGroup).use(List).use(Cell).use(Picker).use(CellGroup).use(Toast).use(AddressEdit).use(Tabs).use(Tab).use(PullRefresh).use(Area).use(Icon).use(Dialog).use(NoticeBar).use(Step).use(Steps).use(Empty).use(Tag).use(Pagination).use(Search)

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
