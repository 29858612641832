import axios from './http'

export default{
    // 获取轮播图
	getBanner(){
		return axios.get('/ads?type=1')
	},
	
	getWaterfall(){
        return axios.get('/articles?type=img')
	},
    getTabBars(){
        return axios.get('/get-navs')
    }
}