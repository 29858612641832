import Mock from "mockjs"

// import

Mock.mock('/api/index/banner', {
	"status":1,
	"message":"SUCCESS",
	"result":[
		{
			"pic":"/images/banner/banner_01.jpg",
			"url":"#"
		},
		{
			"pic":"/images/banner/banner_02.jpg",
			"url":"#"
		},
		{
			"pic":"/images/banner/banner_03.jpg",
			"url":"#"
		}
	]
})

// 首页nav
Mock.mock('/api/index/getTabBars', {
	"status":1,
	"message":"SUCCESS",
	"result":[
		{
			title:'form表单',
			icon:'/images/icon/nav_01.png',
			url:'#/form'
		},
		{
			title:'图文列表',
			icon:'/images/icon/nav_02.png',
			url:'#/list'
		},
		{
			title:'文章详情',
			icon:'/images/icon/nav_03.png',
			url:'#/article'
		},
		{
			title:'瀑布流样式',
			icon:'/images/icon/nav_04.png',
			url:'#/waterfall'
		},
		{
			title:'滑动菜单',
			icon:'/images/icon/nav_05.png',
			url:'#/swipe-call'
		},
		{
			title:'菜单六',
			icon:'/images/icon/nav_06.png',
			url:''
		},
		{
			title:'菜单七',
			icon:'/images/icon/nav_07.png',
			url:''
		},
		{
			title:'菜单八',
			icon:'/images/icon/nav_08.png',
			url:''
		}
	]
})

Mock.mock('/api/list/getTabBars', {
	"status":1,
	"message":"SUCCESS",
	"result":[
		{
			id:1,
			name:'热门',
			api:'/list/imgText2'
		},
		{
			id:2,
			name:'推荐',
			api:'/list/imgText1'
		},
		{
			id:3,
			name:'奥运',
			api:'/list/imgText2'
		},
		{
			id:4,
			name:'科技',
			api:'/list/fontList'
		},
		{
			id:5,
			name:'娱乐',
			api:'/list/imgText1'
		},
		{
			id:6,
			name:'体育',
			api:'/list/imgText2'
		},
		{
			id:7,
			name:'财经',
			api:'/list/imgText2'
		}
	]
})

Mock.mock('/api/list/fontList', {
	"status":1,
	"message":"SUCCESS",
	"result|10":[
		{
			title:"@ctitle(8,25)",
			date:'@date("M-d")'
		}
	]
})

Mock.mock('/api/list/imgText1', {
	"status":1,
	"message":"SUCCESS",
	"result|10":[
		{
			title:"@ctitle(8,25)",
			pic:"@image(100x100)",
			date:'@date("M-d")'
		}
	]
})

Mock.mock('/api/list/imgText2', {
	"status":1,
	"message":"SUCCESS",
	"result|10":[
		{
			title:"@ctitle(8,25)",
			date:'@date("yyyy-MM-dd")',
			pic:"@image(150x150)",
			abstract:"@cparagraph"
		}
	]
})

// 瀑布流
Mock.mock('/api/list/waterfall', {
	"status":1,
	"message":"SUCCESS",
	"result|15":[
		{
			pic:"/images/img_0@increment()"+".jpg",
			title:"@ctitle(8,25)"
		}
	]
})