import index from './index'
import list from './list'
import article from './article'
import common from './common'
import demo from './demo'
import register from './register'
import video from './video'

export default {
    index,
    list,
    article,
	common,
    demo,
    register,
    video
}