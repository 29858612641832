import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


/*配置路由*/
const routes=[
    {
		path:"/",
		component: () => import ('../pages/index'),        
        // component: () => import ('../pages/register/index.vue'),
        meta:{
            title:'百春幼儿园'
        }
	},
	{ 
		path:"/video",
		component: () => import ('../pages/video/list.vue'),
        meta:{
            title:'视频集合'
        }
	},
    {
        path:"/video/details",
        component: () => import ('../pages/video/details.vue'),
        meta:{
            title:'视频详情'
        }
    },
    {

        path:"/register/index",
        component: () => import ('../pages/register/index.vue'),
        meta:{
            title:'百春幼儿园预报名登记'
        }
    },
	{
		path:"/register/form",
		component: () => import ('../pages/register/form.vue'),
        meta:{
            title:'完善个人信息'
        }
	},
    {
        path:"/register/query",
        component: () => import ('../pages/register/query.vue'),
        meta:{
            title:'预报名查询'
        }
    },
    {
        path:"/register/student-info",
        component: () => import ('../pages/register/student-info.vue'),
        meta:{
            title:'学生信息'
        }
    },
	{
		path:"/article/list",
		component: () => import ('../pages/article/list.vue'),
        meta:{
            title:'栏目列表'
        }
	},
	{
		path:"/article/details",
		component: () => import ('../pages/article/details.vue'),
        meta:{
            title:'文章详情'
        }
	},
	{
		path:"/waterfall",
		component: () => import ('../pages/waterfall.vue'),
        meta:{
            title:'所有图文'
        }
	},
    {
        path:"/swipe-call",
        component: () => import ('../pages/swipe-call.vue'),
        meta:{
            title:'栏目列表'
        }
    },
    {
        path:"/pay/qr-code",
        component: () => import ('../pages/pay/qr-code.vue'),
        meta:{
            title:'支付'
        }
    },
    {
        path:"/test",
        component: () => import ('../pages/test.vue'),
        meta:{
            title:'test'
        }
    },
    {
        path:"/test1",
        component: () => import ('../pages/test1.vue'),
        meta:{
            title:'test1'
        }
    },
    {
        path:"/map",
        component: () => import ('../pages/map.vue'),
        meta:{
            title:'地图'
        }
    },
    {
        path:"/daohang",
        component: () => import ('../pages/daohang.vue'),
        meta:{
            title:'导航'
        }
    },
    {
        path:"/test4",
        component: () => import ('../pages/test4.vue'),
        meta:{
            title:'test4'
        }
    },
    {
        path:"/help",
        component: () => import ('../pages/help.vue'),
        meta:{
            title:'路由地址'
        }
    },
]
/*实例化路由*/
const router = new VueRouter({
    mode: 'history',  //去掉url中的#
    base: '/dist/',
    routes // （简写）相当于 routes: routes
})
/*导出路由模块*/
export default router
