import axios from './http'

export default {
    test1(){
        return axios.get('/articles?type=img')
    },
    
    test2(){
        return axios.get('/articles?type=img')
    }
}