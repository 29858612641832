<template>
  <div id="app">
   <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
	<router-view></router-view>
  </div>
</template>

<script>
	
	export default{
		mounted(){
			this.$store.dispatch('saveToken')
		}
	}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
/*  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
body{
    margin: 0;
    padding: 0;
}

/* 返回按钮颜色 */
.van-nav-bar .van-nav-bar__left .van-icon,.van-nav-bar .van-nav-bar__text{
    color: #07c160;
}

/* 分页按钮颜色 */
.van-pagination .van-pagination__item{
    color: #07c160;
}
.van-pagination .van-pagination__item--active{
    color: #FFFFFF;
    background-color: #07c160;
}
.font-list a{
    color: #969799;
}
</style>
