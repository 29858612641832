import axios from 'axios'

switch(process.env.NODE_ENV) {
	case "development":
		axios.defaults.baseURL = "https://baichun.api.qxna.com/v1"
		break;
	case "production":
		axios.defaults.baseURL = "https://baichun.api.qxna.com/v1"
		break;
	case "test":
		axios.defaults.baseURL = "http://qq.com"
		break;
	default:
		axios.defaults.baseURL = "https://baichun.api.qxna.com/v1"
		break;
}

// mock
const mock = false
if(mock){
	axios.defaults.baseURL = '/api'
	require('../mock/api')
}


// 超时时间为8秒
axios.defaults.timeout = 8000

// 设置超时时间和跨域是否允许携带凭证
axios.defaults.withCredentials = true

// 设置请求传递数把格式
axios.defaults['Content-Type'] = 'application/json'

// 设置请求拦截器
axios.interceptors.request.use(config => {
	
	if(config.token){
		// let token = 'cendxiaToken'
		let token = ''
		token && (config.headers.token = token)
	}
	
	return config
},error => {
	return Promise.reject(error)
})

// 响应拦截器
axios.interceptors.response.use(response => {
    
    let res = response.data;
        
	// let path = location.hash;
    
    return res;
	
	// if(res.status == 10){
	// 	// 未登录，10为后端给的状态码
	// 	if(path != '#/index'){
	// 		window.location.href = '/login';
	// 	}
	// }else{
	// 	console.log(res.message)
	// }
},error => {
	console.log(error)
	let {
		response
	} = error
	// let response = error
	if(response){
		// 服务器最起码返回结果了
		console.log(response)
	}else{
		// 服务器连结果都没有返回
		if(!window.navigator.onLine){
			// 客户端断网处理
		}
		return Promise.reject(error)
	}
})

export default axios