<template>
	<van-divider dashed :style="{borderColor: '#333333'}"><slot></slot></van-divider>
</template>

<script>
    export default {
        
    }
</script>

<style>
</style>
