import axios from './http'

export default{
    // 获取视频列表，len为获取条数
	getVideoList(){
		return axios.get('/videos')
	},
    // 根据typeid获取对应栏目下的视频
    getVideoByTypeId(type_id){
        return axios.get('/videos?type='+type_id)
    },
    // 根据id获取视频信息
	getVideoById(id){
        return axios.post('/videos/'+id)
    }
}