import axios from './http'

export default{
    // 提交表单
	addForm(data){
		return axios.post('/student', data)
	},
    
    // 查询学生信息
    studentSearch(data){
        return axios.post('/student-search', data)
    },
    
    // 手机号验证
    studentPhone(data){
        return axios.post('/student-tel', data)
    },
    
    // 身份证号验证
    studentIdCard(data){
        return axios.post('/student-only', data)
    }
}