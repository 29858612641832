import storage from "../storage"
export default {
	saveUserNameaa(context, username){
		context.commit('saveUserName', username)
	},	
	saveToken(context){
		let token = storage.getItem('token')
		if(token){
			context.commit('saveToken', token)
		}
	}
	
}