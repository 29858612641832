<template>
	<div>
		<!-- 是否显示分隔线 -->
		<divider v-if="divider"></divider>
		<div class="footer text-align m-t-40">
			<p>{{title}}</p>
			<p>Copyright © 2023 All rights reserved</p>			
			<p>技术支持：软奥信息服务</p>			
			<p>微信：261077</p>
		</div>
	</div>
	
</template>

<script>
	export default {
		props:{
			divider:{
				type:Boolean,
				default:false
			},
		},
        data() {
            return {
                title:''
            }
        },
        mounted() {
            let schoolInfo = this.$storage.getItem('schoolInfo')
            if(schoolInfo) this.title = schoolInfo.title
        }
	}
</script>

<style>
	.footer{
		padding-bottom: 10px;
	}
	.footer p{
		line-height: 25px;
		font-size: 14px;
	}
</style>
